<template>
  <v-container fluid class='pt-0 overflow-y-auto qms_settings_page'>

    <v-card style='max-width:1200px;' :disabled='loading' :loading='loading'>
      <v-card-title class='primary white--text text-h6'>
        <v-icon class='mr-2' dark>$invoice</v-icon>Invoice Options
      </v-card-title>
      <v-card-text class="pb-0">
        <v-list class='transparent pb-0'>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="">Invoice Number Prefix</v-list-item-title>
              <v-list-item-subtitle>
                Helps differentiate invoice numbers generated in LaserCAM Cloud from other billing systems.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-text-field
                outlined dense placeholder="eg. INV, ABC, 02" style="max-width:150px;" hide-details
                v-model='settings.prefix' @change='update_prefix' 
              />
            </v-list-item-action>
          </v-list-item>

          <v-divider />

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="">Due Date</v-list-item-title>
              <v-list-item-subtitle>
                Default due date on invoices from the date generated.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-text-field
                outlined dense suffix="days" type="number" style="max-width:150px;" hide-details
                v-model="settings.due_days" @change='update_due_days'
              />
            </v-list-item-action>
          </v-list-item>

          <v-divider />

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="">Comments</v-list-item-title>
              <v-list-item-subtitle>
                Default comments located at the top of an invoice.
                <v-textarea
                  outlined dense hide-details style="max-width:450px;" class="mt-2" rows="3" auto-grow placeholder="eg. Happy Holidays! We'll be away from ..."
                  v-model="settings.default_comments" @change='update_default_comments'
                />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider />

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="">Payment Information</v-list-item-title>
              <v-list-item-subtitle>
                Payment information included at the bottom of invoices.
                <v-textarea
                  outlined dense hide-details style="max-width:450px;" class="mt-2" rows="3" auto-grow :placeholder='"BSB/Acc: 123-456 12345\nRef: Invoice Number"'
                  v-model="settings.payment_information" @change='update_payment_information'
                />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

        </v-list>
      </v-card-text>
    </v-card>


  </v-container>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'InvoiceSettings',

  components: {
  },

  data: () => ({
    loading: false,
    settings: {
      due_days: null,
      prefix: null,
      default_comments: null,
      payment_information: null,
    },
  }),

  computed: {
  },

  methods:{

    ...mapActions("API", ['API']),

    refresh(){
      this.loading = true;
      this.API({ method: 'GET', endpoint: `billing/settings` })
      .then(data => this.settings = { ...this.settings, ...data })
      .finally(() => { this.loading = false; });
    },

    updateSetting(setting, value){
      this.loading = true;
      let data = { [setting]: value };
      this.API({ method: 'PATCH', endpoint: `billing/settings`, data })
      .then(data => this.settings = { ...this.settings, ...data })
      .finally(() => { this.loading = false; });
    },

    update_due_days(v){ this.updateSetting('due_days', v); },
    update_prefix(v){ this.updateSetting('prefix', v); },
    update_default_comments(v){ this.updateSetting('default_comments', v); },
    update_payment_information(v){ this.updateSetting('payment_information', v); },

  },

  mounted() {
    this.refresh();
  },

};
</script>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "pt-0 overflow-y-auto qms_settings_page",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', {
    staticStyle: {
      "max-width": "1200px"
    },
    attrs: {
      "disabled": _vm.loading,
      "loading": _vm.loading
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text text-h6"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "dark": ""
    }
  }, [_vm._v("$invoice")]), _vm._v("Invoice Options ")], 1), _c('v-card-text', {
    staticClass: "pb-0"
  }, [_c('v-list', {
    staticClass: "transparent pb-0"
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {}, [_vm._v("Invoice Number Prefix")]), _c('v-list-item-subtitle', [_vm._v(" Helps differentiate invoice numbers generated in LaserCAM Cloud from other billing systems. ")])], 1), _c('v-list-item-action', [_c('v-text-field', {
    staticStyle: {
      "max-width": "150px"
    },
    attrs: {
      "outlined": "",
      "dense": "",
      "placeholder": "eg. INV, ABC, 02",
      "hide-details": ""
    },
    on: {
      "change": _vm.update_prefix
    },
    model: {
      value: _vm.settings.prefix,
      callback: function callback($$v) {
        _vm.$set(_vm.settings, "prefix", $$v);
      },
      expression: "settings.prefix"
    }
  })], 1)], 1), _c('v-divider'), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {}, [_vm._v("Due Date")]), _c('v-list-item-subtitle', [_vm._v(" Default due date on invoices from the date generated. ")])], 1), _c('v-list-item-action', [_c('v-text-field', {
    staticStyle: {
      "max-width": "150px"
    },
    attrs: {
      "outlined": "",
      "dense": "",
      "suffix": "days",
      "type": "number",
      "hide-details": ""
    },
    on: {
      "change": _vm.update_due_days
    },
    model: {
      value: _vm.settings.due_days,
      callback: function callback($$v) {
        _vm.$set(_vm.settings, "due_days", $$v);
      },
      expression: "settings.due_days"
    }
  })], 1)], 1), _c('v-divider'), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {}, [_vm._v("Comments")]), _c('v-list-item-subtitle', [_vm._v(" Default comments located at the top of an invoice. "), _c('v-textarea', {
    staticClass: "mt-2",
    staticStyle: {
      "max-width": "450px"
    },
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "rows": "3",
      "auto-grow": "",
      "placeholder": "eg. Happy Holidays! We'll be away from ..."
    },
    on: {
      "change": _vm.update_default_comments
    },
    model: {
      value: _vm.settings.default_comments,
      callback: function callback($$v) {
        _vm.$set(_vm.settings, "default_comments", $$v);
      },
      expression: "settings.default_comments"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {}, [_vm._v("Payment Information")]), _c('v-list-item-subtitle', [_vm._v(" Payment information included at the bottom of invoices. "), _c('v-textarea', {
    staticClass: "mt-2",
    staticStyle: {
      "max-width": "450px"
    },
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "rows": "3",
      "auto-grow": "",
      "placeholder": "BSB/Acc: 123-456 12345\nRef: Invoice Number"
    },
    on: {
      "change": _vm.update_payment_information
    },
    model: {
      value: _vm.settings.payment_information,
      callback: function callback($$v) {
        _vm.$set(_vm.settings, "payment_information", $$v);
      },
      expression: "settings.payment_information"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }